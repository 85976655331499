import React from "react";
import * as CTAsStyles from "./CTAsSection.module.scss";
import ReadNextCTA from "./ReadNextCTA/ReadNextCTA";

const CTAsSection = ({ data }) => {
  return (
    <section>
      <div>
        <div className={CTAsStyles.CTAsGrid}>
          {data.map((item, index) => (
            <div className={CTAsStyles.CTAsGridItem} key={index}>
              <ReadNextCTA
                subtitle={item.subtitle}
                title={item.title}
                desc={item.desc}
                textColor={item.textColor}
                btnText={item.btnText}
                href={item.href}
                bgColor={item.bgColor}
                btnBg={item.btnBg}
                btnTextColor={item.btnTextColor}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CTAsSection;
