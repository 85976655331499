import React from "react";
import CTAButton from "../../CTAButton/CTAButton";
import { Arrow } from "../../SVGs/SVGs";
import "./ReadNextCTA.scss";

const ReadNextCTA = ({
  subtitle,
  title,
  desc,
  textColor,
  btnText,
  href,
  bgColor,
  btnBg,
  btnTextColor,
}) => {
  return (
    <div
      className={`es-rn-wrapper es-rn-wrapper__${bgColor} es-rn-wrapper__text--${textColor}`}
    >
      {subtitle && <span className="es-rn-subtitle">{subtitle}</span>}
      {title && <h4 className="es-rn-title">{title}</h4>}
      {desc && <p className="es-rn-desc">{desc}</p>}
      <CTAButton
        type="button"
        size="btn-sm"
        text={btnText}
        rounded={true}
        outlined={false}
        btnBG={`es-btn-${btnBg}`}
        spacingClasses=""
        additionalClass=""
        hasIcon={true}
        icon={<Arrow color={btnTextColor} />}
        reverse={true}
        isLink={true}
        isShadow={false}
        href={href}
        btnTextColor={btnTextColor}
      />
    </div>
  );
};

export default ReadNextCTA;
