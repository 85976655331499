import React, { useLayoutEffect, useRef } from "react";
import * as augmentationFeaturesStyles from "./AugmentationFeatures.module.scss";
import { TeamAugmentationFeaturesData } from "../../../../../data";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const AugmentationFeatures = () => {
  const AFSectionRefs = useRef([]);
  AFSectionRefs.current = [];

  const addToAFFeaturesSectionRefs = (el) => {
    if (el && !AFSectionRefs.current.includes(el)) {
      AFSectionRefs.current.push(el);
    }
  };

  let AFSectionAnimation = null;
  useLayoutEffect(() => {
    AFSectionRefs.current.forEach((el, index) => {
      const q = gsap.utils.selector(el);

      AFSectionAnimation = gsap.fromTo(
        q(".AFText-wrapper"),
        {
          opacity: 0,
          y: 100,
        },
        {
          opacity: 1,
          y: 0,
          ease: "expo.out",
          stagger: {
            from: "start",
            amount: 1.5,
          },
          duration: 1.5,
          scrollTrigger: {
            id: `section-${index}`,
            trigger: el,
            start: "top center",
            end: "top top",
          },
        }
      );

      // if (window.innerWidth >= 1024) {
      //   const isPinable =
      //     AFSectionRefs.current.length === index + 1 ? false : true;

      //   ScrollTrigger.create({
      //     trigger: el,
      //     start: "top top",
      //     end: isPinable ? "top top-=100%" : "top top-=100",
      //     pin: false,
      //     pinSpacing: !isPinable,
      //   });
      // }
    });

    return () => {
      AFSectionAnimation.kill();
    };
  }, []);

  return TeamAugmentationFeaturesData.map((item, index) => (
    <section
      ref={addToAFFeaturesSectionRefs}
      key={index}
      className={`es-section__dark ${augmentationFeaturesStyles.augmentationFeaturesSection}`}
    >
      <div>
        <div
          className={`AFText-wrapper ${augmentationFeaturesStyles.augmentationFeaturesGrid}`}
        >
          <div
            className={augmentationFeaturesStyles.augmentationFeaturesGridItem}
          >
            <div className={augmentationFeaturesStyles.augmentationFeaturesImg}>
              <img src={item.img} alt="" />
            </div>
          </div>
          <div
            className={augmentationFeaturesStyles.augmentationFeaturesGridItem}
          >
            <div
              className={`featureSection-${index} ${augmentationFeaturesStyles.augmentationFeaturesText}`}
            >
              <h3>{item.title}</h3>
              <div>
                {item.desc.map((itemDesc, descIndex) => (
                  <p key={descIndex}>{itemDesc}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  ));
};

export default AugmentationFeatures;
